<template>
  <div>
    <DateFilter :with-market-id="false" :defaultMarketIds="[]" @submit="search" />
    <div v-if="dateText.start===dateText.end">ข้อมูลวันที่ <span class="text-primary">{{dateText.start}}</span></div>
    <div v-else>
      ข้อมูลรวม <span class="text-primary">{{totalDay}}</span> วัน
      ตั้งแต่วันที่ <span class="text-primary">{{dateText.start}}</span>
      <span> ถึง
        <span class="text-primary">{{dateText.end}}</span>
      </span>
    </div>
    <div>
      <div class="card mb-0">
        <table class="table-transactions table table-bordered table-pp table-hover mb-0">
          <thead>
            <tr>
              <th width="60">ลำดับ</th>
              <th width="180">รหัสอ้างอิง</th>
              <th width="120">ทำรายการโดย</th>
              <th width="160">วันที่</th>
              <!-- <th>ประเภท</th> -->
              <th>รายละเอียด</th>
              <th width="100">จำนวน</th>
              <th width="100">คงเหลือ</th>
              <th>หมายเหตุ</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id">
              <td class="text-center">{{index+1}}</td>
              <td class="text-center text-info">{{item.refCode}}</td>
              <td class="text-center">{{item.tranferBy.username}}</td>
              <td class="text-center text-secondary">{{item.createdAt | dispDateTime("DD/MM/YYYY")}}<br />{{item.createdAt | dispDateTime("HH:mm:ss")}}</td>
              <!-- <td class="text-center">{{item.type}}</td> -->
              <td class="text-secondary">{{item.description}}</td>
              <td class="text-right">
                <span v-if="item.amounts.withdraw > 0" class="text-danger">-{{item.amounts.withdraw | amountFormat}}</span>
                <span v-else-if="item.amounts.deposit > 0" class="text-success">{{item.amounts.deposit | amountFormat}}</span>
              </td>
              <td class="text-right text-primary">{{item.amounts.after | amountFormat}}</td>
              <td class="text-center text-secondary">{{item.remarks}}</td>
            </tr>
          </tbody>
          <tbody v-if="!isLoading && !items.length">
            <tr>
              <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import DateFilter from '@/views/win-lose-report/components/DateFilter'
import ReportService from "@/services/ReportService"
import moment from '@/helpers/moment'

import Swal from 'sweetalert2'
import cAlert from '@/helpers/alert'

export default {
  name: 'AccountFinanceReport',
  props: ['accountId'],
  components: {
    DateFilter
  },
  data() {
    return {
      isLoading: false,
      filters: {
        startDate: null,
        endDate: null
      },
      data: null
    }
  },
  computed: {
    dateText() {
      return {
        start: moment(this.filters.startDate).add(543, 'y').format('D MMMM YYYY'),
        end: moment(this.filters.endDate).add(543, 'y').format('D MMMM YYYY')
      }
    },
    totalDay() {
      return moment(this.filters.endDate).diff(this.filters.startDate, 'd') + 1
    },
    items() {
      if(!this.data)
        return []

      return this.data.items
    }
  },
  methods: {
    search(filters) {
      this.filters = {
        accountId: this.accountId,
        startDate: filters.date.start,
        endDate: filters.date.end
      }

      this.getReports()
    },
    getReports() {
      this.isLoading = true
      ReportService.getAccountTransactions(this.filters)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('data', this.data)
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td, th {
        padding: 5px;
        vertical-align: top;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td, th {
        font-size: 85%;
      }
    }
  }
}
</style>
<style lang="scss">
.table-transactions {
  tbody {
    tr {
      td {
        .badge {
          font-size: 85%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
